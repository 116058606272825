<template>
  <div style=" background: white;
        padding: 20px;border-right: 6px;">
    <div>
      <search-com @onSubmit="search" @reset="search" @exportExcel="exportExcel"></search-com>
    </div>
    <el-table
        :data="taskBill"
        v-loading="loading"
        @selection-change="select"
        style="width: 100%">

      <el-table-column
          type="selection"
          width="55">
      </el-table-column>

      <el-table-column
          prop="taskNum"
          label="任务编号">
      </el-table-column>

      <el-table-column
          prop="paymentTime"
          label="付款日期">
      </el-table-column>

      <el-table-column
          prop="shopName"
          label="店铺名称">
      </el-table-column>

      <el-table-column
          label="发布/完成">
        <template   slot-scope="scope">
          {{scope.row.publishTotal}}/{{scope.row.finishTotal}}
        </template>
      </el-table-column>

      <el-table-column
          prop="payablePrice"
          label="应付总额">
      </el-table-column>

      <el-table-column
          prop="copingPrice"
          label="实付总额">
      </el-table-column>

      <el-table-column
          label="状态">
        <template slot-scope="scope">
          <el-tag :color="scope.row.statusStyle" size="mini" style="color: #fff">
            {{scope.row.statusLabel}}
          </el-tag>
        </template>
      </el-table-column>

      <el-table-column
          label="操作">
        <template slot-scope="scope">
          <el-link type="primary" @click="seeBillInfo(scope.row)">账单详情</el-link>
        </template>
      </el-table-column>

    </el-table>
    <div style="height: 65px">
      <el-pagination
          style="float: right;margin-top: 20px"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-sizes="[50, 200, 800, 1200]"
          layout="total, pager,  sizes"
          :total="total">
      </el-pagination>
      <bill-info ref="billInfo"></bill-info>
    </div>
  </div>

</template>

<script>
import {TASKBILL,BILLINFO} from "../../api/base";
import SearchCom from './components/searForm'
import BillInfo from './components/billInfo'

export default {
  name: "taskBill",

  data(){
    return {
      loading:false,
      taskBill:[],
      selectList:[],
      total:0,
      listForm:{
        pageIndex:1,
        pageSize:50,
      }
    }
  },
  components:{
    SearchCom,
    BillInfo,
  },
  mounted() {
    this.listForm.beginDate = this.formmateTime(new Date())
    this.listForm.endDate= this.formmateTime(new Date())
    this.getList()
  },
  methods:{
    select(selection){
      this.selectList = selection;
    },
    formatJson: function(filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => {
        return v[j]
      }))
    },
    formmateTime(time){
      let nowDate = new Date(time);
      let year = nowDate.getFullYear();
      let month = nowDate.getMonth() + 1 < 10 ? "0" + (nowDate.getMonth() + 1) : nowDate.getMonth() + 1;
      let day = nowDate.getDate() < 10 ? "0" + nowDate.getDate() : nowDate  .getDate();
      return  year + "-" + month + "-" + day;
    },
    exportExcel(){
      if(this.selectList.length== 0){
        return this.$mes({
          message: '请至少选择一条数据',
          type:'warning'
        })
      }
      import('@/tools/Export2Excel').then(excel => {
        const tHeader = ['任务编号', '付款日期','店铺名称', '发布数量', '完成数量','应付价格','应付总额', '实付总额',  '状态',]
        const filterVal = ['taskNum', 'paymentTime',  'shopName', 'publishTotal', 'finishTotal',
          'unitPrice','payablePrice','copingPrice',  'statusLabel', ]
        const list = this.selectList;
        const data = this.formatJson(filterVal, list);
        excel.export_json_to_excel({
          header: tHeader,
          data,
          filename: '任务账单数据',
          autoWidth: true,
          bookType: 'xlsx'
        })
      })
    },
    seeBillInfo(row){
      this.$axios.get(BILLINFO,{id:row.id}).then(resp=>{
        if(resp.code ==200){
          row.receiptVo = resp.data;
          this.$refs.billInfo.open(resp.data,row)
        }
      })
      //
      // this.$refs.billInfo.open(info,row)
    },
    handleSizeChange(val){
      this.listForm.pageSize = val;
      this.getList()
    },
    handleCurrentChange(val){
      this.listForm.pageIndex = val;
      this.getList()
    },
    search(form){
      this.listForm.pageIndex = 1;
      Object.assign(this.listForm,form)
      this.getList()
    },
    getList(){
      this.selectList = [];
      this.loading = true;
      this.$axios.get(TASKBILL,this.listForm).then(resp=>{
        this.loading = false;
        if(resp.code == 200){
          this.taskBill = resp.data.data;
          this.total = resp.data.totalRow;
        }
      })
    }
  },
}
</script>

<style scoped>

</style>