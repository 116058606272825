<template>
    <div>
        <el-form :inline="true" :model="formInline" >


          <el-form-item label="店铺名称">
              <el-select v-model="formInline.shopId" placeholder="请选择店铺名称"  class="inputWidth" clearable filterable >
                  <el-option :label="item.shopName" :value="item.shopId" v-for="(item,index) in shopList" :key="index"></el-option>
              </el-select>
          </el-form-item>

          <el-form-item label="任务编号">
              <el-input v-model="formInline.taskNum" placeholder="请输入任务编号" class="inputWidth" clearable></el-input>
          </el-form-item>

          <el-form-item label="账单状态">
            <el-select v-model="formInline.status" placeholder="请选择账单状态"  class="inputWidth" clearable  >
              <el-option :label="item.name" :value="item.id" v-for="(item,index) in statusList" :key="index"></el-option>
            </el-select>
          </el-form-item>



          <el-form-item label="付款日期">
              <el-date-picker
                      @change="change"
                      v-model="times"
                      type="daterange"
                      clearable
                      range-separator="至"
                      start-placeholder="开始日期"
                      end-placeholder="结束日期">
              </el-date-picker>
          </el-form-item>


          <el-form-item>
            <el-button type="primary" @click="onSubmit" icon="el-icon-search" >查询</el-button>
            <el-button type="primary" @click="reset" icon="el-icon-refresh">重置</el-button>
            <el-button type="primary" @click="exportExcel" icon="el-icon-download" >下载</el-button>
          </el-form-item>

        </el-form>
    </div>
</template>

<script>
    import {SHOPSELECT} from "../../../api/base";

    const formInline = {
      taskNum: '',
      shopId:'',
      status: '',
      beginDate: '',
      endDate: '',
    };
    const statusList = [
        {name:'待结算',id:1},
        {name:'已结算',id:2},
    ];

    export default {
        name: "taskList",
        props:{
            flagType:{
                default:()=>{
                    return [];
                }
            },

        },
        data() {
            return {
                times:[new Date(),new Date()],
                formInline,
                statusList,
                shopList:[],
            }
        },
        methods: {
            getShopList(){
                this.$axios.get(SHOPSELECT).then(resp=>{
                    console.log(resp)
                    if(resp.code == 200){
                        this.shopList = resp.data;
                    }
                })
            },
            change(arr){
                if(arr){
                    this.formInline.beginDate = this.formmateTime(arr[0])
                    this.formInline. endDate= this.formmateTime(arr[1])
                }else{
                    this.formInline.beginDate = undefined;
                    this.formInline.beginDate = undefined;
                }

            },
            formmateTime(time){
                let nowDate = new Date(time);
                let year = nowDate.getFullYear();
                let month = nowDate.getMonth() + 1 < 10 ? "0" + (nowDate.getMonth() + 1) : nowDate.getMonth() + 1;
                let day = nowDate.getDate() < 10 ? "0" + nowDate.getDate() : nowDate  .getDate();
                return  year + "-" + month + "-" + day;
            },
            onSubmit(){
                this.$emit('onSubmit',this.formInline)
            },
            exportExcel(){
                this.$emit('exportExcel')
            },
            reset(){
                for(let key in this.formInline ){
                    this.formInline[key] = undefined;
                }
                this.times = [new Date(),new Date()]
                this.change(this.times)
                this.$emit('reset',formInline)
            },
        },
        components: {

        },
        mounted() {
          this.getShopList();
          this.change(this.times);
        }
    }
</script>

<style scoped>
    .inputWidth{
        width: 230px;
    }
</style>